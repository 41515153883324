<template>
<div>
  <Row>
    <Col span="24">
      <Form :model="detail" :label-width="100" ref="userForm">
        <FormItem label="头像" v-if="detail.user_avatar">
          <img :src="detail.user_avatar" alt="" style="width: 200px;">
        </FormItem>

        <FormItem label="昵称">
          <Input v-model="detail.user_nick" style="width: 600px" disabled></Input>
        </FormItem>

        <FormItem label="姓名">
          <Input v-model="detail.user_real_name" style="width: 600px" disabled></Input>
        </FormItem>

        <FormItem label="电话">
          <Input v-model="detail.user_phone" style="width: 600px" disabled></Input>
        </FormItem>

        <FormItem label="性别">
          <span v-if="detail.user_gender === 1">男</span>
          <span v-else-if="detail.user_gender === 2">女</span>
          <span v-else>保密</span>
        </FormItem>

        <FormItem label="出生日期">
          <Input v-model="detail.user_birthday" style="width: 600px" disabled></Input>
        </FormItem>

        <FormItem label="工作单位">
          <Input v-model="detail.user_work" style="width: 600px" disabled></Input>
        </FormItem>

      </Form>
    </Col>
  </Row>
</div>
</template>

<script>
import api from '../../api/xiaochengxu';
export default {
  name: "UserDetail",
  data: function(){
    return {
      detail: {},
      user_id: ''
    }
  },
  mounted() {
    this.$emit('lastPage', '/xiaochengxu/user')
    this.user_id = this.$route.query.id;
    this.$http.get(api.userDetail, {params: {user_id: this.user_id}}).then(response => {
      if(response.data.success) {
        this.detail = response.data.res;
      } else {
        this.$Message.error(response.data.err);
        console.log(response);
      }
    })
  },
  destroyed() {
    this.$emit('lastPage', '')
  }
}
</script>

<style scoped>

</style>