const api = {
    // 'baseUrl': 'http://juzhi/',

    // 'upload': 'xiaochengxu/signup/upload',
    // 'addSignup': 'xiaochengxu/signup',
    // 'signupList': 'xiaochengxu/signup',
    // 'active': 'xiaochengxu/active',
    // 'activeList': 'xiaochengxu/active',
    //
    // 'articletypelist': 'xiaochengxu/article_type',
    // 'addarticletype': 'xiaochengxu/article_type',
    //
    // 'articleList': 'xiaochengxu/article',
    // 'addArticle': 'xiaochengxu/article',
    // 'changeArticleRec': 'xiaochengxu/article',
    // 'delArticle': 'xiaochengxu/article',
    //
    // 'delSignup': 'xiaochengxu/signup',
    // 'delActive': 'xiaochengxu/active',
    //
    // 'modArticleType': 'xiaochengxu/article_type',
    //
    // 'signupTable': 'xiaochengxu/signup/table',
    // 'activeOrder': 'xiaochengxu/active/order',
    //
    // 'activeDetail': 'xiaochengxu/active/detail',
    // 'updateActive': 'xiaochengxu/active',
    //
    // 'signupDetail': 'xiaochengxu/signup/detail',
    // 'hiddenSignup': 'xiaochengxu/signup',
    // 'hiddenActive': 'xiaochengxu/active',
    // 'articleDetail': 'xiaochengxu/article/detail',
    // 'modArticle': 'xiaochengxu/article',
    //
    // 'signupSubmitList': 'xiaochengxu/signup/submit',
    // 'signupSubmitDetail': 'xiaochengxu/signup/submitdetail'

    'activityList': 'backend/activity',
    'activityCheck': 'backend/activity/check',
    'activityRec': 'backend/activity/rec',
    'activityDetail': 'backend/activity/detail',
    'activityApplyExport': 'backend/activity/applyexport',
    'activityFundingExport': 'backend/activity/fundingexport',
    'activityManagerExport': 'backend/activity/managerexport',
    'upload': 'setup/swiper/upload',
    'addPaper': 'backend/paper',
    'paperList': 'backend/paper',
    'hiddenPaper': 'backend/paper/hidden',
    'delPaper': 'backend/paper',
    'paperSubmitList': 'backend/paper/submit',
    'paperSubmitDetail': 'backend/paper/submitdetail',
    'paperSubmitExport': 'backend/paper/exportsubmit',
    'paperDetail': 'backend/paper/detail',
    'paperMod': 'backend/paper',

    'userList': 'backend/xcxuser',
    'delUser': 'backend/xcxuser',
    'userDetail': 'backend/xcxuser/detail',

    'quanList': 'backend/quan',
    'hideQuan': 'backend/quan/hide',
    'delQuan': 'backend/quan',
    'quanDetail': 'backend/quan/detail',
    'quanComment': 'backend/quan/comment',
    'delComment': 'backend/quan/delcomment',

    'tagList': 'backend/tag',
    'delTag': 'backend/tag',
    'addTag': 'backend/tag',

    'applyList': 'backend/activity/applylist',

    'activityDel': 'backend/activity',

    'getLogList': 'backend/userlog',

    'gzhGetPaperList': 'backend/gzhpaper',
    'gzhDelPaper': 'backend/gzhpaper',
    'gzhHiddenPaper': 'backend/gzhpaper/hidden',
    'gzhAddPaper': 'backend/gzhpaper',
    'gzhGetPaperDetail': 'backend/gzhpaper/detail',
    'gzhModPaper': 'backend/gzhpaper',
    'gzhPaperSubmitList': 'backend/gzhpaper/submitlist',
};
export default api;